import _config from 'config';

const queryParams = (query: any) => {
    const keys = Object.keys(query || {}).filter((key) => key !== 'page' || query.page !== 1);
    if (!keys.length) return '';

    return `?${keys.map((key) => `${key}=${query[key]}`).join('&')}`;
};

export const baseUrl = (absolute = true): string => {
    return absolute ? _config.SITE_HOST : '';
};

export const withBaseUrl = (url: string): string => {
    if (url && !url.startsWith('http') && !url.startsWith(baseUrl(true))) {
        return `${baseUrl(true)}${url.startsWith('/') ? '' : '/'}${url}`;
    }

    return url;
};

export const withoutBaseUrl = (url: string): string => {
    return url?.replace(baseUrl(true), '');
};

export const facebookImageUrl = () => {
    return `${baseUrl()}/images/facebook-image.jpg`;
};

export const searchUrl = (searchTerm: string, entity?: string) => {
    const url = new URL(`${baseUrl()}/cautare/${searchTerm?.trim()}`);
    return `${url.pathname}${entity ? `?entity=${entity}` : ''}`;
};

export const authorPageUrl = (author: any) => {
    const url = `${companyUrl()}/echipa`;
    if (!author) return url;

    return `${url}/${author.slug}`;
};

export const blogCategoryUrl = (category: any, query?: { page?: number }) => {
    const url = `${baseUrl(false)}/blog`;
    if (!category) return `${url}${queryParams(query)}`;

    return `${url}/${category.slug}${queryParams(query)}`;
};

export const blogPostUrl = (item: any) => {
    const url = `${baseUrl(false)}/blog`;
    if (!item) return url;

    return `${blogCategoryUrl(item.category || { slug: 'detalii' })}/${item.slug}`;
};

export const testsCategoryUrl = (category: any, query?: { l?: string; page?: number }) => {
    const url = `${baseUrl(false)}/analize`;
    if (!category) return `${url}${queryParams(query)}`;

    return `${url}/${category.slug}${queryParams(query)}`;
};

export const testUrl = (item: any) => {
    const url = `${baseUrl(false)}/analize`;
    if (!item) return url;

    return `${testsCategoryUrl(item.category || { slug: 'detalii' })}/${item.slug}`;
};

export const recommendedTestsUrl = (query: any, queryP?: { page?: number }) => {
    const url = `${baseUrl(false)}/analize/recomandate`;
    if (!query?.sex) return `${url}${queryParams(queryP)}`;

    return `${url}/${query.sex}/${query.age_category_slug || ''}${queryParams(queryP)}`;
};

export const diseasePageUrl = (item: any) => {
    const url = `${baseUrl(false)}/analize/afectiuni-medicale`;
    if (!item) return url;

    return `${url}/${item.slug}`;
};

export const anatomicPartUrl = (item: any) => {
    const url = `${baseUrl(false)}/analize/afectiuni-medicale`;
    if (!item) return url;

    return `${url}/anatomie/${item.slug}`;
};

export const ageCategoryUrl = (item: any, sex?: string) => {
    const url = `${baseUrl(false)}/analize/recomandate`;
    if (!item) return url;

    if (!sex) return `${url}/${item.slug}`;

    return `${url}/${sex}/${item.slug}`;
};

export const stateUrl = (item: any) => {
    const url = `${baseUrl(false)}/locatii`;
    if (!item) return url;

    return `${url}/${item.slug}`;
};

export const locationUrl = (item: any) => {
    const url = `${baseUrl(false)}/locatii`;
    if (!item) return url;

    return `${cityUrl(item.city)}/${item.slug}`;
};

export const cityUrl = (item: any) => {
    const url = `${baseUrl(false)}/locatii`;
    if (!item) return url;

    return `${url}/${item.state?.slug || 'detalii'}/${item.slug}`;
};

export const myAccountUrl = (path?: string) => {
    return `${baseUrl(false)}/contul-meu${path || ''}`;
};

export const companyUrl = () => {
    return `${baseUrl(false)}/compania`;
};

export const labLandingUrl = () => {
    return `${companyUrl()}/laborator-analize`;
};

export const satisfactionFormUrl = () => {
    return `${baseUrl(false)}/chestionar-de-satisfactie`;
};

export const appointmentUrl = (locationCode?: string) => {
    return `${baseUrl(false)}/programare${locationCode ? `?location_code=${locationCode}` : ''}`;
};

export const contactUrl = () => {
    return `${baseUrl(false)}/contact`;
};

export const thirtyYearsUrl = () => {
    return `${baseUrl(false)}/30-ani`;
};

export const jobUrl = (item?: any) => {
    return `${companyUrl()}/cariere${item ? `/${item.slug}` : ''}`;
};

export const resultUrl = (resultCode: string) => {
    return `${myAccountUrl('/rezultate')}${resultCode ? `/${resultCode}` : ''}`;
};

export const faqUrl = (item?: any) => {
    const url = `${baseUrl(false)}/intrebari-frecvente`;
    if (!item) return url;

    return `${url}/${item.slug}`;
};
